// utils/api.js
export const fetchTeamMemberOptions = async (newInputValue, inputValue, title, tenure, dth) => {
    return fetch(`/api/team_members/?rep_name=${String(inputValue)}&title=${title}&tenure=${tenure}&dth=${dth}`)
    .then(res => res.json())
    .then(json => {
        const res = json.modifiedData.filter((value, index, self) => {
            return String(value.label.toLowerCase()).includes(String(newInputValue).toLowerCase()) &&
                index === self.findIndex((t) => (
                t.place === value.place && t.label === value.label
            ))
        })
        return res
    })
};

export const fetchTeamMemberById = async (employeeId) => {
    return fetch(`/api/team_member/${employeeId}`)
        .then(res => res.json())
        .then(json => json)
        .catch(err => console.log(err))
}