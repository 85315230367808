import { useEffect, useState } from 'react';
import { fetchTeamMemberById } from '../utlis/api';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function TeamIndex(props) {
  const {
    teamIndexList,
    competition,
    step,
    cookies,
    setTeamMembers,
    setStep,
    setTeamName,
    setEditingRange,
    setEditingTeam,
  } = props

  const [competitionTeams, setCompetitionTeams] = useState([])
  const [teamToEdit, setTeamToEdit] = useState([])
  const [editButtonClicked, setEditButtonClicked] = useState(false)

  useEffect(() => {
    var compTeams = teamIndexList.teams
    var sortedCompetitionTeams = compTeams.sort((a, b) => {
      if (a.teamName < b.teamName) {
          return -1;
      }
      if (a.teamName > b.teamName) {
          return 1;
      }
      return 0;
    });
  
    setCompetitionTeams(sortedCompetitionTeams)
  },[])

  const handleEditTeam = async (team) => {
   setEditButtonClicked(true)
   const arrayOfIndexes = team.teamMembers.map((tm) => {
    return tm.index
   })
   const maxIndex = Math.max(...arrayOfIndexes) + 2
   const minIndex = Math.min(...arrayOfIndexes) + 2

   setEditingRange({startingRange: minIndex, endingRange: maxIndex})
   setEditingTeam(true)
   setTeamName(team.teamName)

   setEditButtonClicked(false)

   team.teamMembers.forEach((tm) => {
    getTeamMember(tm.employeeId)
   })

  }

  useEffect(() => {
    setTeamMembers(teamToEdit)
    if(teamToEdit.length == competition.teamSize ) {
      setStep(2)
    }
  }, [teamToEdit])


  const getTeamMember = async (employeeId) => {
    const teamMember = await fetchTeamMemberById(employeeId)

    if(teamMember) {
      setTeamToEdit(prevState => [...prevState, teamMember])
    }
  }

  return (
    <div className="App">
      <h1 id='team-index-header'>{competition.label} Teams</h1>
        {competitionTeams.map((team, i) => {
          return(
            <div className='competition-team-div' key={(team.teamName + i + competition.label)}>
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: "10px",
                  alignItems: 'center'
                }}
              >
                <h3>{team.teamName}</h3>
                {
                  team.teamCreator === `${cookies.lastName.toLowerCase()} - ${cookies.userBadgeId}` 
                  ? <IconButton
                      onClick={() => handleEditTeam(team)}
                      sx={{
                        padding: '8px 0px',
                        fontSize: editButtonClicked ? '1.5rem' : '1.2rem',
                        color: editButtonClicked ? 'grey' : 'white' 
                      }}
                    >
                      <EditIcon
                        sx={{
                          fontSize: editButtonClicked ? '1.5rem' : '1.2rem',
                          color: editButtonClicked ? 'grey' : 'white' 
                        }}
                      />
                  </IconButton> 
                  : console.log('You do not have permission to edit this team', team.teamName)
                }
              </div>
              {team.teamMembers.map((member,i) => {
                return(
                  <div className="teamMember-div" key={member.teamMember + i + competition.Label}>
                    <p className='teamMember-p'>{member.teamMember}</p>
                  </div>
                )
              })}
            </div>
          )
        })}
    </div>
  );
}

export default TeamIndex;