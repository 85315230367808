import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { fetchTeamMemberOptions } from '../utlis/api';



export default function SearchSelect(props) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false)
  const { title, tenure, setValues, currentValues, dth, count } = props

  const filtOptions = async (newInputValue) => {
    setLoading(true)
    const filteredOptions = await fetchTeamMemberOptions(newInputValue, inputValue, title, tenure, dth)

    setOptions(filteredOptions)
    setLoading(false)
  }

  return (

    <div style={{width: '100%'}} >
      {/* { title === 'Rookie' ?
      <p>Select at least {count} {title} Team Members</p> :
      title === 'Veteran' ?
      <p>Select at most {count} {title} Team Members</p> : */}

      { (title === 'Setter' || title === 'Closer') && (count !== undefined || count !== '' || count !== null) ? <p>Select {count} {title} Team Members</p> : null }
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Autocomplete
          id="search-select"
          sx={{ width: 300 }}
          getOptionLabel={(option) => {
              return typeof option === 'string' ? option : option.label
            }
          }
          loading={loading}
          options={options}
          isOptionEqualToValue={(option, value) => {
            return option.label === value.label
          }}
          includeInputInList={false}
          value={value}
          noOptionsText="Search for Rep by Name"
          selectOnFocus
          onClose={() => {
            setInputValue()
            setOptions([{label: ''}])
            setValue({label: ''})
          }}
          onChange={(event, newValue) => {
            setValues(newValue ? [newValue, ...currentValues] : currentValues)
            setValue(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            setValue(null)
            setInputValue(newInputValue);
            filtOptions(newInputValue)
          }}
          renderInput={(params) => (
            <TextField {...params} label={title} fullWidth/>
          )}
        />
      </Box>
    </div>
  );
}
